import React from 'react';
import { StaticQuery, graphql } from 'gatsby';

interface QProps {
  children: (designs: StaticPageModularHomesDesignsData) => React.ReactNode;
}

interface StaticPageModularHomesDesignsData {
  title: string;
  seoTitle: string;
  seoDescription: string;
  copy: { title: string; body: string }[];
}

export default ({ children }: QProps) => (
  <StaticQuery
    query={query}
    render={data => {
      const seoTitle = data?.modularHomesDesigns?.frontmatter?.seoTitle || '';
      const seoDescription =
        data?.modularHomesDesigns?.frontmatter?.seoDescription || '';
      const title = data?.modularHomesDesigns?.frontmatter?.title || '';
      const copy: { title: string; body: string }[] = (
        data?.modularHomesDesigns?.frontmatter?.copy || []
      ).map((a: any) => ({ title: a?.title || '', body: a?.body || '' }));

      return children({ seoTitle, seoDescription, title, copy });
    }}
  />
);

export const query = graphql`
  query StaticPageModularHomesDesigns {
    modularHomesDesigns: markdownRemark(
      fields: { collection: { eq: "pages" } }
      frontmatter: { page: { eq: "modular-homes-designs" } }
    ) {
      frontmatter {
        title
        seoTitle
        seoDescription
        copy {
          title
          body
        }
      }
    }
  }
`;
